<template>
<div class="">
  welcome to Alaalamy!!
</div>
</template>

<script>
export default {
name: "Home"
}
</script>

<style scoped>

</style>
